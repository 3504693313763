import { useEffect, useState } from "react";
import Balancer from "react-wrap-balancer";
import { useUserData } from "lib/store/user";
import Button from "components/buttonNew/button";
import { useHomepageDatoProps } from "lib/store/homepage";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import type { HomepageDatoProps } from "lib/types/homepage";
import { getDateFormattedNumeric } from "lib/utils";

import Image from "components/imageNew/image";
import FeatureCard from "components/featureCard/featureCard";

const NonProUI = ({ data }: { data: HomepageDatoProps }) => {
  const { track } = useMixpanelTracking();

  return (
    <div className="relative flex h-[212px] flex-col justify-between overflow-hidden max-[400px]:h-[230px] md:h-[386px] lg:h-[394px]">
      <Image
        src={data.briefingsNonProImage.url}
        height={data.briefingsNonProImage.height}
        width={data.briefingsNonProImage.width}
        blurDataURL={data.briefingsNonProImage.blurUpThumb}
        alt={data.briefingsNonProImage.alt || ""}
        className="absolute z-[0] h-100 w-100 object-fill blur-[4px]"
        quality={30} // We're blurring this, so it should be low quality and small
        sizes="300px"
      />

      <div className="absolute flex h-100 flex-col justify-between px-[16px] py-[20px] md:px-[30px] md:py-[50px]">
        <p className="w-4/5 font-bold text-[22px] leading-[130%] max-[340px]:text-[6.5vw] md:text-[32px] md:leading-[130%]">
          <Balancer>{data.briefingsNonProText}</Balancer>
        </p>
        <div className="flex gap-x-[10px]">
          <Button
            component="link"
            colour="navy"
            size={["small", "medium", "medium"]}
            className="ga-briefing-non-pro-primary-link mt-[20px] w-fit whitespace-nowrap max-[340px]:text-[4.5vw] lg:mt-[30px]"
            href={data.briefingsNonProPrimaryButtonUrl}
            onClick={async () => {
              await track("Briefings button click", {
                "Click text": data.briefingsNonProPrimaryButtonText,
                "Click link": data.briefingsNonProPrimaryButtonUrl,
              });
            }}
          >
            {data.briefingsNonProPrimaryButtonText}
          </Button>

          <Button
            component="link"
            colour="grey"
            size={["small", "medium", "medium"]}
            className="ga-briefing-non-pro-secondary-link mt-[20px] w-fit whitespace-nowrap max-[340px]:text-[4.5vw] lg:mt-[30px]"
            href={data.briefingsNonProSecondaryButtonUrl}
            onClick={async () => {
              await track("Briefings button click", {
                "Click text": data.briefingsNonProSecondaryButtonText,
                "Click link": data.briefingsNonProSecondaryButtonUrl,
              });
            }}
          >
            {data.briefingsNonProSecondaryButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

const ProUI = ({ data }: { data: HomepageDatoProps }) => {
  const { track } = useMixpanelTracking();

  return (
    <div className="flex flex-col justify-between gap-x-[30px] overflow-hidden md:flex-row-reverse md:justify-end">
      <div className="flex flex-col overflow-hidden pb-[20px] md:w-7/12 md:pb-0 md:pt-[18px] lg:w-1/2">
        <p className="mb-[30px] font-bold text-[28px] leading-[150%]">
          <Balancer>{data.briefingsProText}</Balancer>
        </p>

        <Button
          component="link"
          colour="navy"
          size={"small"}
          className="ga-briefing-pro-link w-fit whitespace-nowrap max-[340px]:text-[4.5vw]"
          href={data.briefingsProButtonUrl}
          onClick={async () => {
            await track("Briefings button click", {
              "Click text": data.briefingsProButtonText,
              "Click link": data.briefingsProButtonUrl,
            });
          }}
        >
          {data.briefingsProButtonText}
        </Button>
      </div>

      <div className="min-w-[270px] md:w-2/5">
        <div
          className={
            "h-[394px] max-w-[330px] max-lg:mx-auto max-lg:min-w-[270px] lg:min-w-[270px]"
          }
        >
          {data.featuredBriefing && (
            <FeatureCard
              component={"link"}
              href={data.featuredBriefing.url}
              title={data.featuredBriefing.title}
              wrapperClasses={"h-full"}
              theme={"pro"}
              excerpt={data.featuredBriefing.subtitle}
              leftLabel={data.featuredBriefing.marketLabel}
              rightLabel={getDateFormattedNumeric(
                data.featuredBriefing.publishTimestamp
              )}
              backgroundImage={{
                ...data.featuredBriefing.briefingImage,
                sizes:
                  "(max-width: 768px) 500px, (max-width: 1200px) 500px, 500px",
              }}
              withPaywall={false}
              onClick={async () => {
                await track("Briefings featured click", {
                  "Click text": data.featuredBriefing!.title,
                  "Click link": data.featuredBriefing!.url,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const Briefings = () => {
  const data = useHomepageDatoProps();
  const { readerSubscriptionLevel } = useUserData();
  const isPro = readerSubscriptionLevel === "pro";

  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    setIsHydrated(true);
  }, []);

  return (
    <section className="h-100 w-100 border-t-2 border-black-rock pb-[30px]">
      <div className="flex h-[45px] items-center">
        <h3 className="font-bold">Briefings</h3>
      </div>
      <div>
        {isPro && isHydrated ? <ProUI data={data} /> : <NonProUI data={data} />}
      </div>
    </section>
  );
};
